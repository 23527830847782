import { observable } from "mobx";
import React, { createContext, useContext } from "react";

export class Store {
	@observable currentUser;

	constructor(initialState) {
		Object.assign(this, initialState);
	}

	login(currentUser) {
		this.currentUser = currentUser;
	}

	logout() {
		this.currentUser = null;
	}
}

const storeContext = createContext(null);

export const StoreProvider = ({ store, children }) => {
	return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
	const store = useContext(storeContext);
	if (!store) {
		throw new Error("You must supply a StoreProvider");
	}
	return store;
};

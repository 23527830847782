import ms from "modularscale-js";

const parseNum = num => parseFloat(num.toFixed(2));

const scale = {
	base: 1,
	ratio: 1.5
};

const getLineHeight = multiplier => `${parseNum((scale.base * scale.ratio) * multiplier) }`;

export const type = {
	scale,
	baseSpacing: multiplier => `${parseNum(ms(0, scale) * multiplier)}rem`,
	h1: {
		fontSize: `${parseNum(ms(3, scale))}rem`,
		lineHeight: `${getLineHeight(1 * 2)}rem`,
		margin: `0 0 ${getLineHeight(1)}rem 0`
	},
	h2: {
		fontSize: `${parseNum(ms(2, scale))}rem`,
		lineHeight: `${getLineHeight(1 * 2)}rem`,
		margin: `0 0 ${getLineHeight(1)}rem 0`
	},
	h3: {
		fontSize: `${parseNum(ms(1, scale))}rem`,
		lineHeight: `${getLineHeight(1.5)}rem`,
		margin: `0 0 ${getLineHeight(1.5)}rem 0`
	},
	h4: {
		fontSize: `${parseNum(ms(0, scale))}rem`,
		lineHeight: `${getLineHeight(1)}rem`,
		margin: `0 0 ${getLineHeight(1)}rem 0`
	},
	h5: {
		fontSize: `${parseNum(ms(-0.5, scale))}rem`,
		lineHeight: `${getLineHeight(0.5)}rem`,
		margin: `0 0 ${getLineHeight(0.5)}rem 0`
	},
	h6: {
		fontSize: `${parseNum(ms(-1, scale))}rem`,
		lineHeight: `${getLineHeight(0.5)}rem`,
		margin: `0 0 ${getLineHeight(0.5)}rem 0`
	}
};

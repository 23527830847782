export const preloadAssets = () =>
  new Promise(resolve => {
    if (
      window &&
      window.preloadableAssets &&
      typeof window.preloadableAssets === "object"
    ) {
      const [head] = document.getElementsByTagName("head");
      for (let asset of window.preloadableAssets) {
        const [v] = Object.values(asset);
        const link = document.createElement("link");
        link.href = v;
        link.rel = "prefetch";
        link.as = "script";
        head.appendChild(link);
      }
      resolve(true);
    } else {
      resolve(null);
    }
  });

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const MultiRadio = ({ items, selectedItem, handleChange }) => (
	<Container>
		{items.map(item => (
			<Item key={item.id} selected={selectedItem === item.value} onClick={() => {
				if (selectedItem === item.value) {
					handleChange(0)
				} else {
					handleChange(item.value)
				}
			}}>
				{item.label}
			</Item>
		))}
	</Container>
);

const Container = styled.ul`
	display: flex;
	flex-flow: row nowrap;
	margin: 0;
	padding: 0;
	list-style: none;
`;
const Item = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	min-height: ${({ theme }) => theme.type.h1.fontSize};
	background: ${({ theme, selected }) => (selected ? theme.colors.gold.primary : theme.colors.primary3Light)};
	color: ${({ selected }) => (selected ? "#000" : "#fff")};
	border-right: 1px solid #000;
	cursor: pointer;

	&:last-child {
		border-right: 0;
	}

	&:hover {
		background: ${({ theme, selected }) => (selected ? theme.colors.gold.primary : theme.colors.grey.dark)};
		color: ${({ selected }) => (selected ? "#000" : "#fff")};
	}

	${({ theme }) => theme.mediaQueries.tablet`
		min-height: ${({ theme }) => theme.type.h2.fontSize};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		min-height: ${({ theme }) => theme.type.h2.fontSize};
	`};
`;

MultiRadio.propTypes = {
	items: PropTypes.array,
	selectedItem: PropTypes.any,
	handleChange: PropTypes.any
};

export default MultiRadio;

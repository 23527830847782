import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ClickOutside from "react-onclickout";

const Autocomplete = ({ options, setOptions, placeholder, onChange, setValue, value }) => (
	<ClickOutside
		onClickOut={() => {
			if (setOptions) {
				setOptions([]);
			}
		}}
	>
		<Container>
			<Input
				type="text"
				placeholder={placeholder ? placeholder : "Søk i våre eiendommer..."}
				onChange={({ target: { value } }) => {
					if (setValue) {
						setValue(value);
					}
					if (onChange) {
						onChange(value);
					}
				}}
				value={value}
			/>
			{options && options.length ? (
				<SearchResults>
					{options.map(o => (
						<SearchResult
							key={o.text}
							onClick={() => {
								window.location.href = "https://privatmegleren.no?q=" + o.text;
							}}
						>
							{o.text}
						</SearchResult>
					))}
				</SearchResults>
			) : null}
		</Container>
	</ClickOutside>
);

const Container = styled.div`
	position: relative;
	display: flex;
	width: 100%;
`;

const Input = styled.input.attrs(props => ({
	autoFocus: true
}))`
	display: flex;
	width: 100%;
	height: ${({ theme }) => theme.type.h1.fontSize};
	margin-bottom: 10px;
	background: #2a2a2a;
	color: #fff;
	border: 0;
	padding: 0 12px;
	border-radius: 0;
	-webkit-appearance: none;

	&:focus {
		outline: 0;
	}

	${({ theme }) => theme.mediaQueries.tablet`
		height: ${({ theme }) => theme.type.h2.fontSize};
		margin-bottom: 0; 
		margin-top: 11px;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		height: ${({ theme }) => theme.type.h2.fontSize};
		margin-bottom: 0;
		margin-top: 11px;
	`};
`;

const SearchResults = styled.ul`
	position: absolute;
	top: ${({ theme }) => theme.type.h1.fontSize};
	left: 0;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;

	${({ theme }) => theme.mediaQueries.tablet`
		top: ${({ theme }) => theme.type.h2.fontSize};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		top: ${({ theme }) => theme.type.h2.fontSize};
	`};
`;

const SearchResult = styled.li`
	display: flex;
	align-items: center;
	background: ${({ theme }) => theme.colors.grey.darkest};
	height: ${({ theme }) => theme.type.h1.fontSize};
	padding: 0 12px;
	margin-top: 1px;
	font-size: 15px;
	cursor: pointer;

	${({ theme }) => theme.mediaQueries.tablet`
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};

	&:hover {
		background: ${({ theme }) => theme.colors.grey.dark};
	}
`;

Autocomplete.propTypes = {
	onChange: PropTypes.any,
	setValue: PropTypes.any,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	setOptions: PropTypes.any
};

export default Autocomplete;

import { css } from "styled-components";

export const mediaQueries = {
  tabletPortrait: (...args) => css`
    @media (min-width: 768px) and (max-width: 1024px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      ${css(...args)};
    }
  `,
  navbar: (...args) => css`
    @media (min-width: 1080px) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media (min-width: 1025px) {
      ${css(...args)};
    }
  `,
  desktopMd: (...args) => css`
    @media (min-width: 1140px) {
      ${css(...args)};
    }
  `,
  desktopLg: (...args) => css`
    @media (min-width: 1280px) {
      ${css(...args)};
    }
  `,
  desktopXl: (...args) => css`
    @media (min-width: 1440px) {
      ${css(...args)};
    }
  `
};

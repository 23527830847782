import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

export const GlobalContext = React.createContext();

export const GlobalState = Component =>
	withRouter(
		class GlobalState extends React.Component {
			static propTypes = {
				location: PropTypes.any
			};
			state = {
				BETA_FEATURES: {
					LIKES: false,
					CARD_NAV: false
				},
				preferences: {
					saved: false,
					saveLocation: false
				},
				location: null,
				navContext: "DEFAULT"
			};
			UNSAFE_componentWillMount() {
				if (this.props.location.pathname === "/kjope-bolig" && this.state.navContext !== "PROPERTY_SEARCH") {
					this.setState({ navContext: "PROPERTY_SEARCH" });
				}
			}
			componentDidUpdate(prevProps) {
				if (prevProps.location.pathname !== this.props.location.pathname) {
					if (this.props.location.pathname === "/kjope-bolig" && this.state.navContext !== "PROPERTY_SEARCH") {
						this.setState({ navContext: "PROPERTY_SEARCH" });
					} else if (prevProps.location.pathname === "/kjope-bolig" && this.navContext !== "DEFAULT") {
						this.setState({ navContext: "DEFAULT" });
					}
				}
			}
			update = value =>
				new Promise(resolve => {
					this.setState(
						prevState => ({
							...value,
							...(!prevState.preferences.saved ? { preferences: { ...prevState.preferences, saved: true } } : {})
						}),
						() => resolve()
					);
				});
			render() {
				return (
					<GlobalContext.Provider value={{ globalState: this.state, updateGlobalState: this.update }}>
						<Component />
					</GlobalContext.Provider>
				);
			}
		}
	);

import React, { useState } from "react";
import styled from "styled-components";
import Downshift from "downshift";
import { ApolloConsumer } from "@apollo/client";
import PropTypes from "prop-types";
import Input from "@common/website/components/shared/form/input";

const Autocomplete = ({ query, keyName }) => {
	const [items, setState] = useState([]);
	return (
		<ApolloConsumer>
			{client => (
				<Downshift
					id="autocomplete"
					itemToString={item => (item && item.text ? item.text : "")}
					onChange={selected => {
						console.log(selected);
					}}
					onInputValueChange={inputValue =>
						client
							.query({
								query,
								fetchPolicy: "network-only",
								variables: {
									query: inputValue
								}
							})
							.then(({ data }) => {
								setState(data[Object.keys(data)[0]]);
							})
					}
				>
					{downshift => (
						<CONTAINER {...downshift.getRootProps()}>
							<Input {...downshift.getInputProps()} style={{ width: "100%" }} />
							{downshift.isOpen ? (
								<UL>
									{items.map((item, index) =>
										!(items[index - 1] && items[index - 1].text === item.text) ? (
											<LI
												key={item[keyName]}
												highlight={index === downshift.highlightedIndex}
												{...downshift.getItemProps({
													key: item[keyName],
													index: index,
													item
												})}
											>
												<h6>{item.text}</h6>
												<small>{item.county}</small>
											</LI>
										) : null
									)}
								</UL>
							) : null}
						</CONTAINER>
					)}
				</Downshift>
			)}
		</ApolloConsumer>
	);
};

const CONTAINER = styled.div`
	position: relative;
	width: 100%;
`;

const UL = styled.ul`
	position: absolute;
	top: 49px;
	left: 0;
	width: 100%;
	margin: 0;
	padding: 0;
	background: #2a2a2a;
	list-style: none;
`;

const LI = styled.li`
	display: flex;
	flex-flow: column;
	background: ${({ highlight }) => (highlight ? "#303030" : "#2a2a2a")};
	border-bottom: 1px solid #000;
	padding: 12px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}
`;

Autocomplete.propTypes = {
	query: PropTypes.any,
	keyName: PropTypes.any
};

export default Autocomplete;

import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { post } from "axios";
import debounce from "lodash.debounce";
import fetch from "isomorphic-unfetch";

import searchIcon from "@common/website/assets/svg/search_icon.svg";

import Logo from "@common/website/components/shared/logo";
import { GlobalContext } from "@common/website/state";
import { Autocomplete2 } from "@common/website/components/shared/form";
import { Route } from "react-router-dom";

const Navbar = ({ location }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [query, setQuery] = useState("");
	const [searchResults, setSearchResults] = useState(null);
	const [alertText, setAlertText] = useState(null);
	const search = async () => {
		const {
			data: [results]
		} = await post("https://pmapi.reeltime.no/newapi/autocomplete", {
			q: query && query.length ? query.toLowerCase() : query
		});
		if (results && results.options) {
			setSearchResults(results.options);
		}
	};

	useEffect(() => {
		fetch(
			"https://spreadsheets.google.com/feeds/list/1i_uGtxqXFp6_JPHqkXfMbRffnIaLETZxsuex8HPv8gk/od6/public/values?alt=json"
		)
			.then(res => res.json())
			.then(result => {
				setAlertText(result.feed.entry[0].gsx$front.$t);
			});
	}, []);

	useEffect(() => {
		if (location && location.pathname && (showMenu || showSearch)) {
			setShowMenu(false);
			setShowSearch(false);
		}
	}, [location.pathname]);

	useMemo(
		debounce(() => search(), 100),
		[query]
	);
	return (
		<GlobalContext.Consumer>
			{() => (
				<>
					<Container role="navigation">
						<Inner>
							<Logo />
							<Right>
								<SearchField
									onSubmit={e => {
										e.preventDefault();
										if (window && window.location) {
											console.log(query);
											window.location.href = "https://privatmegleren.no/?q=" + encodeURIComponent(query);
										}
									}}
									showSearch={showSearch}
									showMenu={showMenu}
								>
									<Autocomplete2
										placeholder="Skriv ditt søkeord her..."
										onChange={val => setQuery(val)}
										value={query}
										options={searchResults || []}
										setOptions={val => {
											setSearchResults(val);
										}}
									/>
									<SearchButton
										onClick={e => {
											if (e) {
												e.preventDefault();
											}
											if (window && window.location) {
												window.location.href = "https://privatmegleren.no/?q=" + encodeURIComponent(query);
											}
										}}
									>
										<SearchIcon dangerouslySetInnerHTML={{ __html: searchIcon }} />
										Søk
									</SearchButton>
								</SearchField>

								<PopularNav>
									<NavItem to="/kjope-bolig?q=">Kjøpe bolig</NavItem>
									<NavItem href="https://privatmegleren.no/nybygg">Nybygg</NavItem>
									<NavItem href="https://privatmegleren.no/verdivurdering">Selge bolig / Verdivurdering</NavItem>
									<NavItem href="https://privatmegleren.no/kontorer">Kontor / megler</NavItem>
									{/* <NavItem to={"/logg-inn"} as={Link}>
								Logg inn
							</NavItem> */}
								</PopularNav>
								{!showSearch ? (
									<SearchToggle
										showSearch={showSearch}
										showMenu={showMenu}
										onClick={() => {
											if (!showSearch) {
												setShowSearch(true);
											}
										}}
									>
										<SearchIcon dangerouslySetInnerHTML={{ __html: searchIcon }} />
										Søk
									</SearchToggle>
								) : null}

								<NavToggle
									showSearch={showSearch}
									showMenu={showMenu}
									onClick={() => {
										if (!showMenu && !showSearch) {
											setShowMenu(true);
										} else if (showMenu && !showSearch) {
											setShowMenu(false);
										} else if (showMenu && showSearch) {
											setShowSearch(false);
										} else if (!showMenu && showSearch) {
											setShowSearch(false);
										}
									}}
								>
									{showSearch ? "Lukk søk" : showMenu ? "Lukk meny" : "Meny"}
								</NavToggle>
							</Right>
						</Inner>
					</Container>
					<MainNavContainer showMenu={showMenu && !showSearch}>
						<MainNav>
							<MainNavItem href="https://privatmegleren.no/verdivurdering">Selge bolig</MainNavItem>
							<MainNavItem tiny={true} href="https://privatmegleren.no/content/tipsradvedsalg">
								Råd ved salg av bolig
							</MainNavItem>
							<MainNavItem tiny={true} href="https://privatmegleren.no/content/e-takst-nokkelen-til-boligsalg">
								Hva er E-takst?
							</MainNavItem>
							<MainNavItem tiny={true} last href="https://privatmegleren.no/content/kjope-eller-selge-forst">
								Når lønner det seg å selge?
							</MainNavItem>
							<MainNavItem href="https://privatmegleren.no/kontorer">Kontor / megler</MainNavItem>
							<MainNavItem to="/kjope-bolig?q=">Kjøpe bolig</MainNavItem>
							<MainNavItem tiny={true} last to="/kjop">
								PrivatMegleren KJØP
							</MainNavItem>
							<MainNavItem href="https://privatmegleren.no/nybygg">Nybygg</MainNavItem>
							<MainNavItem href="https://privatmegleren.no/boligsok">PM Boligsøk</MainNavItem>
							<MainNavItem href="https://privatmegleren.no/finansiering">Finansiering</MainNavItem>
							<MainNavItem href="https://privatmegleren.no/om-oss">Om oss</MainNavItem>
							<MainNavItem href="https://privatmegleren.no/personvern">Personvern</MainNavItem>
						</MainNav>
					</MainNavContainer>
					<Route
						exact
						path="/"
						render={() =>
							alertText && (
								<Alert>
									<InfoIcon />
									<AlertText dangerouslySetInnerHTML={{ __html: alertText }} />
									{/*
								<p>
									For å redusere smittefare viser vi boligen til hver og én interessent.{" "}
									<AlertLink href="https://privatmegleren.no/content/boligsalg-tilrettelagt-mot-smittefare">Klikk her for mer informasjon.</AlertLink>
								</p>
								*/}
								</Alert>
							)
						}
					/>
				</>
			)}
		</GlobalContext.Consumer>
	);
};

const AlertText = styled.p`
	a {
		text-decoration: none;
		color: white;
		border-bottom: solid 2px #e08752;
	}
`;

const Alert = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 72px;
	width: 100%;
	float: left;
	min-height: 58px;
	background: rgb(37, 37, 37);
	z-index: 100;

	p {
		margin: 0 0 0 10px;
		font-weight: 600;
	}

	a {
		font-weight: 600;
	}

	.info-alert-icon {
		flex: 0 0 25px;
	}

	@media all and (max-width: 1060px) {
		padding: 20px;
	}
`;

const InfoIcon = () => {
	return (
		<svg
			className="info-alert-icon"
			style={{ width: "25px", height: "25px" }}
			focusable="false"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#e08752"
				d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
			></path>
		</svg>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 72px;
	${({ theme }) => theme.spacing.default("padding", "default", null, true, null, true)};
	background: #000;
	color: #fff;
	z-index: 950;
	font-size: 18px;

	${({ theme }) => theme.mediaQueries.navbar`
		${theme.spacing.default("padding", "lg", null, true, null, true)};
	`};

	a {
		text-decoration: none;
		color: #fff;
		cursor: pointer;
		:hover {
			color: ${({ theme }) => theme.colors.gold.primary};

			svg {
				path {
					fill: ${({ theme }) => theme.colors.gold.primary};
				}
			}
		}
	}
`;

const Inner = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	width: 100%;
	flex: 1 0 auto;
	align-items: center;
`;

const Right = styled.div`
	display: flex;
	align-items: center;
	flex: 1 1 0%;
	justify-content: flex-start;

	${({ theme }) => theme.mediaQueries.navbar`
		justify-content: flex-end;
	`};
`;

const SearchField = styled.form`
	display: ${({ showSearch, showMenu }) => (showSearch || showMenu ? "flex" : "none")};
	position: fixed;
	top: 72px;
	left: 0;
	align-items: center;
	width: 100%;
	padding: 0 1rem 1rem 1rem;
	height: auto;
	background: #000;

	input {
		margin-top: 0;
		margin-bottom: 0;
	}

	${({ theme }) => theme.mediaQueries.navbar`
		position: fixed;
		display: ${({ showSearch }) => (showSearch ? "flex" : "none")};
		top: 0;
		left: 72px;
		width: calc(100% - 185px);
		height: 72px;
		padding: 0;
		background: transparent;
	`};

	div:first-child {
		flex: 1;
	}
`;

const PopularNav = styled.nav`
	display: none;

	a {
		display: flex;
		align-items: center;
		height: 100%;

		${({ theme }) => theme.spacing.default("margin", "xxl", null, null, null, true)};
		color: #fff;
		text-decoration: none;
		white-space: nowrap;
	}

	${({ theme }) => theme.mediaQueries.navbar`
		display: inline-flex;
		height: 100%;
		float: right;
	`};
`;

const NavItem = styled(({ href, to, className, children }) =>
	to ? (
		<Link to={to} className={className}>
			{children}
		</Link>
	) : (
		<a href={href || ""} className={className}>
			{children}
		</a>
	)
)``;

const Toggle = styled.a`
	display: flex;
	align-items: center;
	${({ theme }) => theme.spacing.default("margin", "xxl", null, null, null, true)};
	white-space: nowrap;
`;

const SearchToggle = styled(Toggle)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	display: ${({ showSearch, showMenu }) => (showSearch || showMenu ? "none" : "flex")};
	height: 36px;
	margin-left: 0;
	background: ${({ theme, showSearch }) => (showSearch ? theme.colors.grey.dark : "transparent")};
	padding: 0 12px;

	${({ theme }) => theme.mediaQueries.navbar`
		display: flex;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		${({ theme }) => theme.spacing.default("margin", "xxl", null, null, null, true)};

		&& {
			color: ${({ showSearch, theme }) => (showSearch ? theme.colors.gold.primary : "#fff")};
		}
	`};

	svg {
		path {
			fill: ${({ theme, showSearch }) => (showSearch ? theme.colors.gold.primary : "#fff")};
		}
	}
`;

const SearchIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	height: 18px;
	margin-right: 9px;

	svg {
		display: flex;
		width: 18px;
		height: 18px;
		fill: white;
	}
`;

const NavToggle = styled(Toggle)`
	margin-left: auto;
	${({ theme }) => theme.mediaQueries.navbar`
		${({ theme }) => theme.spacing.default("margin", "xxl", null, null, null, true)};
	`};
`;

const SearchButton = styled.a`
	display: flex;
	align-items: center;
	margin-left: 5px;
	height: 54px;
	padding: 0 24px;
	background: ${({ theme }) => theme.colors.grey.dark};

	&& {
		color: ${({ theme }) => theme.colors.gold.primary};
	}

	${({ theme }) => theme.mediaQueries.navbar`
		display: none;
	`};

	${({ theme }) => theme.mediaQueries.tablet`
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};

	svg {
		path {
			fill: ${({ theme }) => theme.colors.gold.primary};
		}
	}
`;

// Main nav

const MainNavContainer = styled.div`
	position: fixed;
	top: 72px;
	left: 0;
	display: block;
	width: 100%;
	height: calc(100vh - 72px);
	background: #000;
	z-index: 900;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	${({ theme }) => theme.mediaQueries.navbar`
		background: rgba(0,0,0,.95);
	`};
	opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
	pointer-events: ${({ showMenu }) => (showMenu ? "all" : "none")};
	transition: ease-in-out 250ms opacity;

	a {
		text-decoration: none;
		color: #fff;
		cursor: pointer;
		:hover {
			color: ${({ theme }) => theme.colors.gold.primary};
		}
	}

	${({ theme }) => theme.mediaQueries.navbar`
		overflow: hidden;
	`};
`;

const MainNav = styled.nav`
	display: block;
	width: 100%;
	height: auto;
	padding-top: 72px;
	padding-bottom: 72px;

	${({ theme }) => theme.mediaQueries.navbar`
		padding-top: 24px;
		background: rgba(0,0,0,.95);
	`};
`;
const MainNavItem = styled(({ children, to, href, className }) =>
	to ? (
		<Link to={to} className={className}>
			{children}
		</Link>
	) : (
		<a href={href} className={className}>
			{children}
		</a>
	)
)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${({ tiny, last }) => (tiny ? `${last ? "0 12px 12px 12px" : "0 12px"}` : "6px 12px")};
	flex: 1;
	font-size: ${({ tiny }) => (tiny ? "13px" : "18px")};

	${({ theme }) => theme.mediaQueries.navbar`
		justify-content: flex-end;
		padding: ${({ tiny, last }) => (tiny ? `0px 24px ${last ? "12" : "0"}px 12px` : "6px 24px 6px 12px")};
	`};
`;

export default withRouter(Navbar);

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Logo as SvgLogo, LogoSmall } from "./svglogo";

import mark from "@common/website/assets/images/pm_logo_new.png";
import mark2x from "@common/website/assets/images/pm_logo_new@2x.png";
import type from "@common/website/assets/images/pm_logo_new_text.png";
import type2x from "@common/website/assets/images/pm_logo_new_text@2x.png";

const Logo = ({ footer }) => (
	<>
		<Container className="mobile" asTag="a" href="https://privatmegleren.no" to="/" footer={footer}>
			<LogoSmall />
		</Container>
		<Container className="desktop" asTag="a" href="https://privatmegleren.no" to="/" footer={footer}>
			<SvgLogo />
		</Container>
	</>
);

const Container = styled(({ to, asTag, href, className, children }) => {
	if (asTag === "a") {
		return (
			<a as={asTag} href={href} className={className}>
				{children}
			</a>
		);
	} else {
		return (
			<Link to={to} className={className}>
				{children}
			</Link>
		);
	}
})`
	display: inline-flex;
	flex-flow: ${({ footer }) => (footer ? "column" : "row nowrap")};
	align-items: center;
	height: 100%;

	span {
		&:first-of-type {
			margin: ${({ footer, theme }) => (footer ? "0 0 12px 0" : `0, ${theme.spacing.getSpacing("sm")}, 0, 0`)};
		}
		&:last-of-type {
			margin-right: 0;
		}
	}

	&.desktop {
		display: none;
	}
	${({ theme }) => theme.mediaQueries.desktop`
		&.desktop{ display: inline-flex; margin-top: 9px; }
		&.mobile{ display: none; }
		`};
`;

Logo.propTypes = {
	footer: PropTypes.any
};

export default Logo;

import React from "react";
import styled from "styled-components";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";

import checkmark from "@common/website/assets/svg/checkmark.svg";
import { COUNTY_LOCATIONS_QUERY } from "@common/website/gql/queries/locations.gql";

// SELECTED LOCATION ARRAY MÅ FJERNES!!!!

class Checkbox extends React.PureComponent {
	static propTypes = {
		label: PropTypes.string,
		value: PropTypes.any,
		onChange: PropTypes.any,
		bucket: PropTypes.any,
		checked: PropTypes.any,
		client: PropTypes.any,
		county: PropTypes.any,
		onSubChange: PropTypes.any,
		citiesBucket: PropTypes.any,
		bydelBucket: PropTypes.any,
		locations: PropTypes.any
	};

	state = {
		showLocations: false,
		locations: [],
		selectedLocations: []
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.checked && this.props.checked && this.props.county) {
			this.getLocations();
			this.setState({ showLocations: true });
		}
		if (prevProps.checked && !this.props.checked) {
			this.setState({ showLocations: false, selectedLocations: [] }, () => {
				if (this.props.onSubChange) {
					this.props.onSubChange([]);
				}
			});
		}
	}

	getLocations = () => {
		this.props.client
			.query({
				query: COUNTY_LOCATIONS_QUERY,
				variables: {
					county: this.props.label
				}
			})
			.then(({ data }) => {
				if (data && data.getCountyLocations) {
					this.setState({ locations: data.getCountyLocations });
				} else {
					this.setState({
						locations: []
					});
				}
			});
	};

	render() {
		const { label, value, bucket, checked, onChange } = this.props;
		const doc_count = bucket?.length && bucket[0]?.count ? bucket[0].count : null;
		return (
			<Container>
				<CheckboxInput
					id={label}
					type="checkbox"
					checked={checked}
					onChange={() =>
						onChange(typeof parseInt(value) !== "undefined" || parseInt(value) === 0 ? value : value.toLowerCase())
					}
				/>
				<Label htmlFor={label}>
					<Checkmark dangerouslySetInnerHTML={{ __html: checkmark }} />
					<Text>
						{label} {doc_count ? <Count>({doc_count})</Count> : null}
					</Text>
				</Label>
				{this.props.county && this.state.showLocations && this.state.locations && this.state.locations.length
					? this.state.locations.map((loc, i) => {
							let [citiesAgg] = this.props.citiesBucket?.filter(b => loc && b.key === loc);
							let [bydelAgg] = this.props.bydelBucket?.filter(b => loc && b.key === loc);

							if (!citiesAgg || !(citiesAgg && citiesAgg.doc_count)) {
								citiesAgg = { doc_count: 0 };
							}
							if (!bydelAgg || !(bydelAgg && bydelAgg.doc_count)) {
								bydelAgg = { doc_count: 0 };
							}
							const totalAgg = citiesAgg.doc_count + bydelAgg.doc_count;

							return loc && loc.length ? (
								<Container key={loc} nested first={i === 0}>
									<CheckboxInput
										id={loc}
										type="checkbox"
										checked={this.props.locations && this.props.locations.includes(loc)}
										onChange={() => {
											const prevState = this.state.selectedLocations.slice();
											const idx = prevState.findIndex(e => e === loc);

											if (idx != -1) {
												const newState = prevState;
												newState.splice(idx, 1);
												this.setState({ selectedLocations: newState }, () => {
													this.props.onSubChange(this.state.selectedLocations);
												});
											} else {
												const newState = [...prevState, loc];
												this.setState({ selectedLocations: newState }, () => {
													this.props.onSubChange(this.state.selectedLocations);
												});
											}
										}}
									/>
									<Label htmlFor={loc}>
										<Checkmark dangerouslySetInnerHTML={{ __html: checkmark }} />
										<Text>
											{loc} {totalAgg && totalAgg > 0 ? <Count>({totalAgg})</Count> : null}
										</Text>
									</Label>
								</Container>
							) : null;
					  })
					: null}
			</Container>
		);
	}
}

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	flex-flow: column;
	width: 100%;
	margin-top: ${({ nested, first }) => (nested && first ? "12px" : "0")};
	margin-bottom: 10px;
	margin-left: ${({ nested }) => (nested ? "35px" : "0")};

	${({ theme }) => theme.mediaQueries.tablet`
		margin-bottom: 0;
		margin-top: 0;
		margin-left: ${({ nested }) => (nested ? "27px" : "0")};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		margin-bottom: 0;
		margin-top: 0;
		margin-left: ${({ nested }) => (nested ? "27px" : "0")};
	`};
`;

const Label = styled.label`
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	&:before {
		content: "";
		display: inline-block;
		width: ${({ theme }) => theme.type.h3.fontSize};
		height: ${({ theme }) => theme.type.h3.fontSize};
		background: ${({ theme }) => theme.colors.grey.dark};
	}

	${({ theme }) => theme.mediaQueries.tablet`
		&:before {
			width: ${({ theme }) => theme.type.h4.fontSize};
			height: ${({ theme }) => theme.type.h4.fontSize};
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		&:before {
			width: ${({ theme }) => theme.type.h4.fontSize};
			height: ${({ theme }) => theme.type.h4.fontSize};
		}
	`};
`;

const CheckboxInput = styled.input`
	position: absolute;
	left: -999px;

	&:checked + ${Label} {
		svg {
			display: flex;
		}
	}
`;

const Checkmark = styled.span`
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ theme }) => theme.type.h3.fontSize};
	height: ${({ theme }) => theme.type.h3.fontSize};

	svg {
		display: none;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};

		path {
			fill: ${({ theme }) => theme.colors.gold.primary};
		}
	}

	${({ theme }) => theme.mediaQueries.tablet`
		top: 4px;
		left: 0;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};

		svg {
			display: none;
			width: ${({ theme }) => theme.type.h5.fontSize};
			height: ${({ theme }) => theme.type.h5.fontSize};

			path {
				fill: ${({ theme }) => theme.colors.gold.primary};
			}
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		top: 4px;
		left: 0;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};

		svg {
			display: none;
			width: ${({ theme }) => theme.type.h5.fontSize};
			height: ${({ theme }) => theme.type.h5.fontSize};

			path {
				fill: ${({ theme }) => theme.colors.gold.primary};
			}
		}
	`};
`;

const Text = styled.span`
	margin-left: 12px;
`;

const Count = styled.span`
	font-size: 13px;
	opacity: 0.4;
`;

export default withApollo(Checkbox);

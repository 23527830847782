import React, { useState } from "react";
import styled from "styled-components";
import { lighten } from "polished";
import PropTypes from "prop-types";

import chevronIcon from "@common/website/assets/svg/chevron_icon.svg";

const icons = {
	chevron: chevronIcon
};

const Dropdown = ({ icon, disabled, options, selectedOption, handleChange }) => {
	const [show, setShow] = useState(false);
	return (
		<Container className="dropdown" disabled={disabled}>
			<Button onClick={() => setShow(!show)}>
				{!disabled ? (
					<>
						Sorter etter: {selectedOption && selectedOption.label ? selectedOption.label : ""}
						{icon ? <Icon dangerouslySetInnerHTML={{ __html: icons[icon] }} /> : null}
					</>
				) : (
					"Sorter etter: søkeord"
				)}
			</Button>
			{show ? (
				<Options>
					{options.map(o => (
						<Option
							key={o.id}
							onClick={() => {
								setShow(false);
								handleChange(o);
							}}
						>
							{o.label}
						</Option>
					))}
				</Options>
			) : null}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
`;
const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ theme }) => theme.type.h1.fontSize};
	${({ theme, dangerouslySetInnerHTML }) =>
		dangerouslySetInnerHTML ? null : theme.spacing.default("padding", "sm", null, true, null, true)};
	background: ${({ theme }) => theme.colors.primary3Light};
	border: ${({ theme }) => `1px solid ${theme.colors.grey.dark}`};
	color: ${({ theme }) => lighten(0.4, theme.colors.grey.dark)};
	text-decoration: none;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	&:hover {
		background: ${({ theme }) => theme.colors.gold.primary};
		color: #000;
		svg {
			path {
				fill: #000;
			}
		}
	}

	svg {
		display: flex;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};
		margin-left: 9px;
		path {
			fill: ${({ theme }) => lighten(0.4, theme.colors.grey.dark)};
		}
	}

	${({ theme }) => theme.mediaQueries.tablet`
		justify-content: flex-start;
		height: ${({ theme }) => theme.type.h2.fontSize};
		min-width: 200px;
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		justify-content: flex-start;
		height: ${({ theme }) => theme.type.h2.fontSize};
		min-width: 200px;
	`};
`;
const Icon = styled.div`
	${({ theme }) => theme.mediaQueries.tablet`
		margin-left: auto;
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		margin-left: auto;
	`};
`;

const Options = styled.ul`
	display: flex;
	flex-flow: column;
	position: absolute;
	top: ${({ theme }) => theme.type.h1.fontSize};
	color: white;
	left: 0;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	z-index: 10;
	list-style: none;
	cursor: pointer;
	background-color: ${({ theme }) => theme.colors.grey.dark};
	${({ theme }) => theme.mediaQueries.tablet`
		top: ${({ theme }) => theme.type.h2.fontSize};
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		top: ${({ theme }) => theme.type.h2.fontSize};
	`};

	&:focus {
		color: white;
	}
`;

const Option = styled.li`
	display: flex;
	white-space: nowrap;
	align-items: center;
	height: ${({ theme }) => theme.type.h1.fontSize};
	max-width: calc(100% - 10px);
	${({ theme }) => theme.spacing.default("padding", "sm", true, true, true, true)}};
	padding-top: 0;
	padding-bottom: 0;
	background: ${({ theme }) => theme.colors.grey.dark};
	border-bottom: 1px solid #000;
	cursor: pointer;

	&:hover {
		color: white;
	}

	&:last-child {
		border: 0;
	}

	${({ theme }) => theme.mediaQueries.tablet`
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};
`;

Dropdown.propTypes = {
	icon: PropTypes.string,
	options: PropTypes.array,
	selectedOption: PropTypes.object,
	handleChange: PropTypes.any,
	disabled: PropTypes.any
};

export default Dropdown;

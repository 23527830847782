import React from 'react';
import styled from 'styled-components';


export const Logo = ({ className }) => {
    return (
        <StyledLogoWrapper className={className}>
            <svg
                width="252"
                height="36"
                viewBox="0 0 252 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.4762 20.2479H16.3835V20.3406V23.5539C16.3835 24.1587 16.4492 24.6002 16.6865 24.9572C16.8857 25.2569 17.1987 25.4862 17.6647 25.7079H12.8292C13.1581 25.4886 13.3772 25.244 13.5157 24.9284C13.6809 24.552 13.7268 24.0846 13.7268 23.4625V14.9721C13.7268 14.3494 13.6775 13.879 13.5106 13.5011C13.3714 13.1859 13.1536 12.9418 12.8306 12.7267H19.0276C20.556 12.7267 21.8168 13.0575 22.6929 13.6904C23.5651 14.3206 24.064 15.2555 24.064 16.4873C24.064 17.7192 23.5651 18.6541 22.6945 19.2842C21.82 19.9171 20.5626 20.2479 19.0408 20.2479H16.4762ZM16.4894 13.0376H16.3967V13.1303V19.8704V19.9631H16.4894H18.1419C19.2592 19.9631 20.0868 19.6132 20.6337 18.9964C21.179 18.3813 21.4337 17.5139 21.4337 16.5004C21.4337 15.4865 21.1756 14.6192 20.6287 14.0043C20.0802 13.3876 19.2527 13.0376 18.1419 13.0376H16.4894ZM24.4413 8.90332L24.3482 8.66757L24.2672 8.90774L23.601 10.8823H23.1755L24.3027 7.54104H24.3186L25.1942 9.74233L25.2804 9.95896L25.3666 9.74233L26.2423 7.54104H26.2582L27.3853 10.8823H26.9466L26.2804 8.90774L26.1994 8.66757L26.1063 8.90332L25.2834 10.9868H25.2642L24.4413 8.90332Z"
                    fill="url(#paint90_linear)"
                    stroke="url(#paint1_linear)"
                    strokeWidth="0.185482"
                />
                <rect
                    x="0.566038"
                    y="0.566038"
                    width="35.3017"
                    height="34.8679"
                    stroke="url(#paint90_linear)"
                    strokeWidth="1.13208"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M58.2892 15.3676C58.2892 15.8104 58.1301 16.1924 57.8167 16.5021C57.5028 16.8122 57.072 16.9694 56.5359 16.9694H54.1962V13.7378H56.5359C57.072 13.7378 57.5028 13.8963 57.8163 14.2092C58.1301 14.5225 58.2892 14.9122 58.2892 15.3676ZM60.8709 15.3676C60.8709 14.2351 60.4553 13.2831 59.635 12.5386C58.8172 11.7965 57.7739 11.4204 56.5345 11.4204H51.6951C51.6509 11.4204 51.6145 11.4564 51.6145 11.5V24.2424C51.6145 24.2864 51.6509 24.322 51.6951 24.322H54.1156C54.1598 24.322 54.1962 24.2864 54.1962 24.2424V19.2868H56.5408C57.7781 19.2868 58.8197 18.9121 59.6368 18.1731C60.4556 17.4317 60.8709 16.4882 60.8709 15.3676Z"
                    fill="url(#paint3_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M66.4458 24.322H68.8664C68.9106 24.322 68.947 24.2864 68.947 24.2424V13.7378H71.2866C71.8881 13.7378 72.3829 13.8949 72.7568 14.205C73.1322 14.5158 73.3142 14.9206 73.3142 15.4423C73.3142 15.9511 73.1304 16.3478 72.7519 16.6554C72.3748 16.9631 71.8754 17.1188 71.2679 17.1188H69.112C69.0678 17.1188 69.0314 17.1548 69.0314 17.1988V17.7219C69.0314 17.7401 69.0378 17.7576 69.0495 17.7719L74.3748 24.2927C74.39 24.3112 74.4133 24.322 74.4373 24.322H77.4251C77.4562 24.322 77.4849 24.3042 77.4983 24.2763C77.5114 24.2484 77.5071 24.2152 77.487 24.1914L73.1046 18.985C73.8397 18.8607 74.4865 18.4706 75.0282 17.8236C75.604 17.1363 75.8959 16.3352 75.8959 15.4423C75.8959 14.2916 75.4835 13.3226 74.6699 12.5634C73.8581 11.8049 72.7579 11.4204 71.399 11.4204H66.4458C66.4016 11.4204 66.3652 11.4564 66.3652 11.5V24.2424C66.3652 24.2864 66.4016 24.322 66.4458 24.322Z"
                    fill="url(#paint4_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M84.955 24.2424V11.5C84.955 11.4564 84.919 11.4204 84.8744 11.4204H82.4542C82.4097 11.4204 82.3733 11.4564 82.3733 11.5V24.2424C82.3733 24.2864 82.4097 24.322 82.4542 24.322H84.8744C84.919 24.322 84.955 24.2864 84.955 24.2424Z"
                    fill="url(#paint5_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M95.7301 24.7425H96.3065C96.3383 24.7425 96.3666 24.7243 96.3797 24.6964L102.563 11.5339C102.575 11.5088 102.573 11.4801 102.558 11.4571C102.543 11.4344 102.517 11.4204 102.49 11.4204H99.8898C99.8584 11.4204 99.8298 11.4386 99.8163 11.4669L95.9948 19.6887L92.1825 11.4669C92.1691 11.4386 92.1404 11.4204 92.109 11.4204H89.5089C89.4813 11.4204 89.4559 11.4344 89.441 11.4574C89.4262 11.4801 89.424 11.5091 89.4357 11.5339L95.6569 24.6964C95.67 24.7243 95.6986 24.7425 95.7301 24.7425Z"
                    fill="url(#paint6_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M111.395 18.6697H108.953L110.178 16.0531L111.395 18.6697ZM110.49 11H109.913C109.882 11 109.853 11.0182 109.84 11.0461L103.619 24.2089C103.608 24.2334 103.61 24.2624 103.624 24.2851C103.639 24.3081 103.665 24.3221 103.692 24.3221H106.292C106.324 24.3221 106.353 24.3039 106.366 24.2753L107.876 20.9871H112.471L114 24.2756C114.013 24.3043 114.042 24.3221 114.073 24.3221H116.673C116.701 24.3221 116.727 24.3081 116.741 24.2854C116.756 24.2624 116.758 24.2337 116.746 24.2089L110.563 11.0461C110.55 11.0182 110.522 11 110.49 11Z"
                    fill="url(#paint7_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M128.257 11.4204H118.963C118.919 11.4204 118.883 11.4564 118.883 11.5V13.6582C118.883 13.7022 118.919 13.7378 118.963 13.7378H122.333V24.2424C122.333 24.2864 122.369 24.322 122.414 24.322H124.834C124.879 24.322 124.915 24.2864 124.915 24.2424V13.7378H128.257C128.301 13.7378 128.338 13.7022 128.338 13.6582V11.5C128.338 11.4564 128.301 11.4204 128.257 11.4204Z"
                    fill="url(#paint8_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M146.921 24.2676C146.932 24.3004 146.963 24.3221 146.997 24.3221H148.627C148.653 24.3221 148.677 24.3099 148.693 24.2893C148.708 24.2683 148.712 24.2414 148.703 24.2173L144.285 11.0545C144.274 11.0227 144.245 11.001 144.21 11C144.183 11.0028 144.146 11.0192 144.133 11.0506L140.344 20.5227L136.545 11.0506C136.533 11.0192 136.511 11.0024 136.468 11C136.434 11.001 136.405 11.0227 136.394 11.0545L131.973 24.2173C131.965 24.2414 131.969 24.2683 131.985 24.2893C132 24.3099 132.024 24.3221 132.05 24.3221H133.57C133.605 24.3221 133.636 24.3004 133.647 24.2676L136.603 15.5638L140.219 24.6919C140.231 24.7223 140.261 24.7425 140.294 24.7425C140.327 24.7425 140.357 24.7223 140.369 24.6919L143.976 15.5935L146.921 24.2676Z"
                    fill="url(#paint9_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M161.583 24.2424V22.8693C161.583 22.8253 161.547 22.7894 161.502 22.7894H155.542V18.5674H161.266C161.31 18.5674 161.347 18.5318 161.347 18.4878V17.1147C161.347 17.0707 161.31 17.035 161.266 17.035H155.542V12.9531H161.502C161.547 12.9531 161.583 12.9171 161.583 12.8735V11.5C161.583 11.4564 161.547 11.4204 161.502 11.4204H153.91C153.866 11.4204 153.83 11.4564 153.83 11.5V24.2424C153.83 24.2864 153.866 24.322 153.91 24.322H161.502C161.547 24.322 161.583 24.2864 161.583 24.2424Z"
                    fill="url(#paint10_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M179.77 17.0724H173.54C173.495 17.0724 173.459 17.108 173.459 17.152V18.5251C173.459 18.5691 173.495 18.6051 173.54 18.6051H178.138C178.125 19.3538 177.927 20.0547 177.549 20.6895C177.158 21.346 176.599 21.8786 175.89 22.2715C175.179 22.665 174.378 22.8641 173.506 22.8641C172.598 22.8641 171.754 22.6416 170.997 22.2023C170.24 21.7634 169.635 21.154 169.2 20.3917C168.764 19.6293 168.543 18.7814 168.543 17.8714C168.543 16.9617 168.766 16.1138 169.205 15.3511C169.643 14.5884 170.25 13.9791 171.006 13.5401C171.763 13.1012 172.604 12.8784 173.506 12.8784C174.959 12.8784 176.257 13.4888 177.365 14.6925C177.394 14.7243 177.443 14.7274 177.476 14.7002L178.628 13.7472C178.662 13.7193 178.667 13.669 178.639 13.6354C178.003 12.8759 177.236 12.2854 176.359 11.8807C175.483 11.4763 174.523 11.2709 173.506 11.2709C172.309 11.2709 171.184 11.5685 170.164 12.1551C169.144 12.7418 168.325 13.5513 167.729 14.5616C167.133 15.5718 166.831 16.6855 166.831 17.8714C166.831 19.057 167.132 20.1706 167.724 21.1809C168.317 22.1908 169.134 23.0003 170.154 23.5869C171.175 24.174 172.303 24.4715 173.506 24.4715C174.737 24.4715 175.854 24.1694 176.827 23.5733C177.799 22.9776 178.554 22.1761 179.072 21.191C179.588 20.2073 179.851 19.1118 179.851 17.9353V17.152C179.851 17.108 179.815 17.0724 179.77 17.0724Z"
                    fill="url(#paint11_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M193.245 22.7894H187.284V11.5C187.284 11.4564 187.248 11.4204 187.203 11.4204H185.653C185.608 11.4204 185.572 11.4564 185.572 11.5V24.2424C185.572 24.2864 185.608 24.322 185.653 24.322H193.245C193.289 24.322 193.325 24.2864 193.325 24.2424V22.8693C193.325 22.8253 193.289 22.7894 193.245 22.7894Z"
                    fill="url(#paint12_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M206.331 11.4204H198.739C198.695 11.4204 198.658 11.4564 198.658 11.5V24.2424C198.658 24.2864 198.695 24.322 198.739 24.322H206.331C206.376 24.322 206.412 24.2864 206.412 24.2424V22.8693C206.412 22.8253 206.376 22.7894 206.331 22.7894H200.37V18.5674H206.095C206.139 18.5674 206.175 18.5318 206.175 18.4878V17.1147C206.175 17.0707 206.139 17.035 206.095 17.035H200.37V12.9531H206.331C206.376 12.9531 206.412 12.9171 206.412 12.8735V11.5C206.412 11.4564 206.376 11.4204 206.331 11.4204Z"
                    fill="url(#paint13_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M217.839 18.844C218.742 18.8055 219.514 18.456 220.134 17.8044C220.791 17.1136 221.125 16.2528 221.125 15.246C221.125 14.145 220.739 13.222 219.979 12.5026C219.22 11.7843 218.176 11.4204 216.875 11.4204H212.677C212.632 11.4204 212.596 11.4564 212.596 11.5V24.2424C212.596 24.2864 212.632 24.322 212.677 24.322H214.227C214.272 24.322 214.308 24.2864 214.308 24.2424V12.9531H216.742C217.538 12.9531 218.19 13.1647 218.68 13.5827C219.166 13.9979 219.413 14.5577 219.413 15.246C219.413 15.9347 219.166 16.4945 218.68 16.909C218.19 17.3273 217.538 17.5393 216.742 17.5393H214.804C214.773 17.5393 214.744 17.5571 214.731 17.585C214.718 17.613 214.722 17.6462 214.742 17.6703L220.32 24.2934C220.335 24.3116 220.358 24.322 220.382 24.322H222.339C222.371 24.322 222.399 24.3042 222.412 24.2759C222.425 24.248 222.421 24.2148 222.401 24.1911L217.839 18.844Z"
                    fill="url(#paint14_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M235.02 11.4204H227.428C227.383 11.4204 227.347 11.4564 227.347 11.5V24.2424C227.347 24.2864 227.383 24.322 227.428 24.322H235.02C235.064 24.322 235.1 24.2864 235.1 24.2424V22.8693C235.1 22.8253 235.064 22.7894 235.02 22.7894H229.059V18.5674H234.783C234.828 18.5674 234.864 18.5318 234.864 18.4878V17.1147C234.864 17.0707 234.828 17.035 234.783 17.035H229.059V12.9531H235.02C235.064 12.9531 235.1 12.9171 235.1 12.8735V11.5C235.1 11.4564 235.064 11.4204 235.02 11.4204Z"
                    fill="url(#paint15_linear)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M251.897 11.4204H250.347C250.303 11.4204 250.266 11.4564 250.266 11.5V20.6081L241.425 11.0261C241.402 11.002 241.367 10.9936 241.336 11.0052C241.305 11.017 241.285 11.0467 241.285 11.0796V24.2424C241.285 24.2864 241.321 24.322 241.365 24.322H242.916C242.96 24.322 242.997 24.2864 242.997 24.2424V15.1343L251.838 24.7163C251.853 24.7334 251.875 24.7425 251.897 24.7425C251.907 24.7425 251.917 24.7407 251.926 24.7372C251.958 24.7254 251.978 24.6957 251.978 24.6628V11.5C251.978 11.4564 251.942 11.4204 251.897 11.4204Z"
                    fill="url(#paint16_linear)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="17.6555"
                        y1="25.632"
                        x2="17.6555"
                        y2="7.4483"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                    <linearGradient
                        id="paint90_linear"
                        x1="17.6555"
                        y1="25.632"
                        x2="17.6555"
                        y2="7.4483"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="17.6555"
                        y1="25.632"
                        x2="17.6555"
                        y2="7.4483"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1="12.409"
                        y1="35.6692"
                        x2="12.409"
                        y2="0"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear"
                        x1="56.2427"
                        y1="11.4204"
                        x2="56.2427"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear"
                        x1="71.9355"
                        y1="11.4204"
                        x2="71.9355"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear"
                        x1="83.6642"
                        y1="11.4204"
                        x2="83.6642"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear"
                        x1="95.9994"
                        y1="11.4204"
                        x2="95.9994"
                        y2="24.7425"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear"
                        x1="110.183"
                        y1="11"
                        x2="110.183"
                        y2="24.3221"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear"
                        x1="123.61"
                        y1="11.4204"
                        x2="123.61"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear"
                        x1="140.339"
                        y1="11"
                        x2="140.339"
                        y2="24.7425"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear"
                        x1="157.706"
                        y1="11.4204"
                        x2="157.706"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear"
                        x1="173.341"
                        y1="11.2709"
                        x2="173.341"
                        y2="24.4715"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear"
                        x1="189.448"
                        y1="11.4204"
                        x2="189.448"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear"
                        x1="202.535"
                        y1="11.4204"
                        x2="202.535"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint14_linear"
                        x1="217.508"
                        y1="11.4204"
                        x2="217.508"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint15_linear"
                        x1="231.224"
                        y1="11.4204"
                        x2="231.224"
                        y2="24.322"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                    <linearGradient
                        id="paint16_linear"
                        x1="246.631"
                        y1="11.0001"
                        x2="246.631"
                        y2="24.7425"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D69568" />
                        <stop offset="0.1875" stopColor="#E2AC7E" />
                        <stop offset="1" stopColor="#C88458" />
                    </linearGradient>
                </defs>
            </svg>
        </StyledLogoWrapper>
    );
};

export const LogoSmall = ({ className }) => {
    return (
        <StyledLogoWrapper className={className}>
            <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.4746 20.4908H16.3818V20.5836V23.8352C16.3818 24.4471 16.4475 24.8934 16.6846 25.2542C16.8843 25.5583 17.1986 25.7909 17.6674 26.016H12.8249C13.1557 25.7935 13.3757 25.5455 13.5145 25.2253C13.6796 24.8448 13.7254 24.3722 13.7254 23.7427V15.1509C13.7254 14.5209 13.6762 14.0452 13.5095 13.6631C13.37 13.3433 13.1512 13.0958 12.8263 12.8776H19.0257C20.5538 12.8776 21.8143 13.2123 22.6902 13.8527C23.5624 14.4904 24.0616 15.4368 24.0616 16.6842C24.0616 17.9316 23.5624 18.878 22.6918 19.5157C21.8175 20.1561 20.5603 20.4908 19.0389 20.4908H16.4746ZM16.4878 13.1944H16.3951V13.2871V20.1077V20.2004H16.4878H18.1401C19.2577 20.2004 20.0853 19.8462 20.6321 19.2221C21.177 18.6 21.4316 17.7227 21.4316 16.6974C21.4316 15.6719 21.1736 14.7946 20.627 14.1727C20.0787 13.5487 19.2512 13.1944 18.1401 13.1944H16.4878ZM24.439 9.01039L24.3457 8.77141L24.2646 9.01478L23.5984 11.0134H23.1727L24.3005 7.62997H24.3159L25.1916 9.8579L25.2779 10.0775L25.3642 9.8579L26.2399 7.62997H26.2553L27.3831 11.0134H26.9441L26.2779 9.01478L26.1968 8.77141L26.1035 9.01039L25.2806 11.1191H25.2619L24.439 9.01039Z"
                    fill="url(#paint100_linear)"
                    stroke="url(#paint101_linear)"
                    strokeWidth="0.185482"
                />
                <rect
                    x="0.566038"
                    y="0.566038"
                    width="35.2979"
                    height="35.2979"
                    stroke="url(#paint102_linear)"
                    strokeWidth="1.13208"
                />
                <defs>
                    <linearGradient
                        id="paint100_linear"
                        x1="17.6538"
                        y1="25.9381"
                        x2="17.6538"
                        y2="7.53723"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                    <linearGradient
                        id="paint101_linear"
                        x1="17.6538"
                        y1="25.9381"
                        x2="17.6538"
                        y2="7.53723"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                    <linearGradient
                        id="paint102_linear"
                        x1="12.4077"
                        y1="36.0953"
                        x2="12.4077"
                        y2="0"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#834628" />
                        <stop offset="0.34375" stopColor="#824528" />
                        <stop offset="0.572917" stopColor="#D7A87C" />
                        <stop offset="0.791667" stopColor="#9C603F" />
                        <stop offset="1" stopColor="#9A5E3D" />
                    </linearGradient>
                </defs>
            </svg>
        </StyledLogoWrapper>
    );
};

const StyledLogoWrapper = styled.div``;
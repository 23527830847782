import { css } from "styled-components";
import { type } from "./type";

const sizes = {
	xxs: 0.25,
	xs: 0.5,
	sm: 0.75,
	default: 1,
	md: 1.25,
	lg: 1.5,
	xl: 1.75,
	xxl: 2
};

export const spacing = {
	default: (spacingType = "padding", size, t, r, b, l) => {
		if (!size || (size && !sizes.hasOwnProperty(size))) {
			throw new Error("Size for spacing not found");
		}

		const s = sizes[size];

		return `
      ${spacingType}: ${
			t || r || b || l
				? `${t ? `${parseFloat(type.baseSpacing(s))}rem` : "0"} ${r ? `${parseFloat(type.baseSpacing(s))}rem` : "0"} ${
						b ? `${parseFloat(type.baseSpacing(s))}rem` : "0"
				  } ${l ? `${parseFloat(type.baseSpacing(s))}rem` : "0"}`
				: `${parseFloat(type.baseSpacing(s))}rem`
		};
    `;
	},
	getSpacing: num => parseFloat(type.baseSpacing(sizes[num]))
};

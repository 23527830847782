import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


const Input = memo(({ onChange }) => <Container type="text" placeholder="Søk..." onChange={onChange} />);

const Container = styled.input`
    width: 100%;
    height: ${({ theme }) => theme.type.h2.fontSize};
    background: #2a2a2a;
    color: #fff;
    border: 0;
    padding: ${({ theme }) => `0 ${theme.type.baseSpacing(1)}`};

    &:focus {
        outline: 0;
    }
`;

Input.propTypes = {
    onChange: PropTypes.any
}

export default Input;
import React from "react";
//import "image-set-polyfill";
import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

import { setConfig } from "react-hot-loader";
import { HelmetProvider } from "react-helmet-async";
import fetch from "isomorphic-unfetch";
import Digger from "@pm/digger-js";
import SharedUtils from "@common/website/components/context/shared_utils";
import { Store, StoreProvider } from "@common/website/state/root_store";
import App from "@common/website/components/app";

const PRODUCTION = process.env.NODE_ENV === "production";
const STAGING = process.env.RELEASE === "staging";

const digger = new Digger({
	apiKey:
		process.env.NODE_ENV === "production"
			? "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJvcmdhbml6YXRpb25faWQiOiIzOCJ9.RhHuQOZLmQFx4BMdiCdFnNehIYR4Qwu2BhPSoGrTNNvOBUeAi0tTCczsIiVHXIWuIJMoz36HThBl5wnoh6EIQMoPtREh8ATnG7sQJKa7dWdIHRC6JkwMXDLOtPo8GSEBaeumsgTwUzQz1dlJ1-ZUkh28-ZwB1PwlVYD8nUc093oYL0iNboj6xWZxDRQQfXunj-3-IvgQ2H8NneVOIDZr8mjixnc11bFzpIjHpToj2rE3_mJ8aea8YvvnuCL0D9BdNLzHukaWCMmsLzLlLms6KHG5NFwrAlGsLRunE4t-AqKmZK9zrMCQ8fBJ4iQgBihOj3tg34n1vpjAAe_Ye8cYXXz-TPYLSfnWhsa3IYqFnt2CzABd98SrVYKrdwfgsscq_2PtuT9Put7PHDQ9TY6xZVV_aSMKrMbZGbzwVkbNDZrulMd4BnqWBvdNMd1Ds1x1YsIwCqnNTACLL6v9WMSwdxddUC0ixCmBs8ive6LWKjli_4vGMg9Rrdv0iNaP_aJPw4rT9RuR-8LTagFAU9JWModCptfN_n-E6DDWBvCJd1A1RgWIME8HTjH3cbPeqoJtIFY7O0RH9689uze3MclyPct7x-PhAuceOrKbAQ5i85osYFtjpEiokbq8Zp1u3tg0ZDEsdXzcGgIafXtI3d7iiT8BKk5CpdGjwwjlRHw3G-4"
			: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJvcmdhbml6YXRpb25faWQiOiIzOCJ9.cya3SPQxfCCC9ztPDFF0tK3ZALp5H--AKrHIxXFfdaDo2qU7lt1a8XOqbYFkA7B819uwNyDh7qBrexdLmiaaEjdEkYk78zfp-I3Ls5vDIlLQ9eSxRP1FzE8z9--ILzC1Pl3rWQP9btz2nFarQ5FmhV2Zil_UzF1BAs9oA4ao7rHBIk7ZJhgttzKhRZBDop0qlG5hmd6WhjNawGHLCDdH-8PeYNkt-JcWYgsSaWKyFtoAWp5Ie4J64VZgE0eYcL-bVdyPtMH_C27mhposWPpmWRQ_hME-xXwdiydeb8WPDyFoHkLqCWO_Jfi9NdH_WDvRUbqK8ejzCuyQQfiiIBASVWjhnKmr65NVXqCjv4rEOS43nT-AY_rqukG7UpfBtQiVoMMT92t2mXbtpszyCvZoFRsdHoynjQOd0Rx_KBaLKfKdkoKZ5kHGsA4jVd6vaguxPB_KzXHj1pCnLGNent7ABZU6ktWFmL1VKYjtfdxZQH2OSV_hxew8LFZvgLX1xUpGAtLzPyoI2ea9p1ANQVMZgQGLrqLfVSTaMarLsN-fzEd12STqhcxmEYBhqOB0V6T8OgS2FqHWo_QlEB7EoExSWXk-445i2600JYUmNjyuPoXE6CpJ6tPCVnWZ4rAwWhGrCWfoJEkBftFqX3w9x9M1iJLPnTj4SYGp-hVYfM1Kw_M",
	debug: process.env.NODE_ENV !== "production"
});

setConfig({
	ignoreSFC: true,
	pureSFC: true,
	pureRender: true,
	logLevel: process.env.NODE_ENV === "production" ? "ignore-errors" : "debug"
});

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

const client = new ApolloClient({
	cache,
	link: ApolloLink.from([
		new BatchHttpLink({
			uri: PRODUCTION
				? STAGING
					? "https://pm2019-staging.reeltime.no/graphql"
					: "https://pm2019.reeltime.no/graphql"
				: "/graphql",
			credentials: "same-origin",
			fetch: fetch
		})
	])
});

const store = new Store(window.__MOBX_STATE__);

const renderApp = () => {
	render(
		<BrowserRouter>
			<HelmetProvider>
				<ApolloProvider client={client}>
					<SharedUtils.Provider
						value={{
							userAgent: window.navigator.userAgent,
							digger
						}}
					>
						<StoreProvider store={store}>
							<App />
						</StoreProvider>
					</SharedUtils.Provider>
				</ApolloProvider>
			</HelmetProvider>
		</BrowserRouter>,
		document.getElementById("root")
	);
};

window.loadApp = async () => {
	await Loadable.preloadReady();
	renderApp();
};

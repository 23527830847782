import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "polished";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Switch, Route, withRouter } from "react-router-dom";
import { observe } from "mobx";
import Helmet from "react-helmet-async";
import rcSliderCSS from "@common/website/assets/css/rc_slider";
import { useStore } from "@common/website/state/root_store";
import { Menu, Footer } from "@pm/gui";

import SharedUtils from "@common/website/components/context/shared_utils";

import theme from "@common/website/components/theme";

import Navbar from "@common/website/components/shared/navbar";
import {
	Home,
	Office,
	OfficesInVicinity,
	Broker,
	Property,
	PropertyRegister,
	PropertySearch,
	Kjop,
	Login,
	ServerError,
	Qr,
	QrBergen,
	NybyggBergen,
	ValdresKampanje,
	Kampanjeside
} from "@common/website/components/routes";

import { getConnectionQuality } from "@common/website/components/helpers/connection";
import { preloadAssets } from "@common/website/components/helpers/assets";

import { CURRENT_ADMIN_USER_QUERY } from "@common/website/gql/queries/authentication.gql";
import { fonts } from "./theme/fonts";

const NotFound = ({ staticContext = {} }) => {
	staticContext.status = 404;
	return <h1>Noe gikk galt..</h1>;
};

const App = ({ location, currentAdmin }) => {
	const { digger } = useContext(SharedUtils);
	const store = useStore();

	observe(store, change => {
		console.log(change);
	});

	useEffect(() => {
		async function connectionQuality() {
			const connectionQuality = await getConnectionQuality();
			if (connectionQuality && connectionQuality !== "2g") {
				await preloadAssets();
			}
		}
	}, []);

	useEffect(() => {
		digger.pageView();

		if (window.scrollY > 0) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		if (currentAdmin.currentAdminUser && !store.currentUser) {
			store.login(currentAdmin.currentAdminUser);
		} else if (store.currentUser && !currentAdmin.loading && !currentAdmin.currentAdminUser) {
			store.logout();
		}
	}, [currentAdmin]);

	return (
		<ThemeProvider theme={theme}>
			<>
				<Helmet>
					<title>PrivatMegleren - Nordeas Eiendomsmeglerkjede</title>
				</Helmet>
				{/*<Navbar />*/}
				<Menu className="header" />
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/404" component={NotFound} />
					<Route path="/500" component={ServerError} />
					<Route path="/logg-inn" component={Login} />
					<Route path="/qr" component={Qr} />
					<Route path="/qrverdibergen" component={QrBergen} />
					<Route path="/nybyggbergen" component={NybyggBergen} />
					<Route path="/valdreskampanje" component={ValdresKampanje} />
					<Route path="/kjop" component={Kjop} />
					<Route path="/kjøp" component={Kjop} />
					<Route path="/:settprispaa(settprispa|settprispaa|settprispå|privatmeglerenverdi)" component={Kampanjeside} />
					<Route path="/kjope-bolig" component={PropertySearch} />
					<Route path="/kontorer/inarheten" component={OfficesInVicinity} />
					<Route path="/(registrerinteressent|meldinteresse)/:id(\d+)" component={PropertyRegister} />
					<Route path="/megler/:name([a-zA-Z]+)" component={Broker} />
					<Route path="/:shortname([a-zA-Z]+)" component={Office} />
					<Route path="/:id(\d+)" component={Property} />
				</Switch>
				<Footer />
				<GlobalStyle />
			</>
		</ThemeProvider>
	);
};

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${rcSliderCSS};
  ${fonts}

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    font-display: fallback;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
    background: ${({ theme }) => theme.colors.primary3};
    color: #fff;
    font-family: 'Geograph-Regular', sans-serif;
    font-size: ${({ theme }) => theme.type.h4.fontSize};
    line-height: ${({ theme }) => theme.type.scale.ratio};

    &.page {
      padding-top: ${({ theme }) => theme.type.baseSpacing(12)};
    }
  }

  .header {
    background: ${({ theme }) => theme.colors.primary3};
  }

  #root {
    display: flex;
    flex-flow: column;
    width: 100%;
    min-height: 100vh;
  }

  h1 {
    color: ${({ theme }) => theme.colors.gold.primary};
  }

  h2,h3,h4,h5,h6 {
  
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'DomaineDispCondRegular', sans-serif;
  }

  h1 {
    font-size: ${({ theme }) => theme.type.h1.fontSize};
    line-height: ${({ theme }) => theme.type.h1.lineHeight};
    margin: ${({ theme }) => theme.type.h1.margin};
  }

  h2 {
    font-size: ${({ theme }) => theme.type.h2.fontSize};
    line-height: ${({ theme }) => theme.type.h2.lineHeight};
    margin: ${({ theme }) => theme.type.h2.margin};
  }

  h3 {
    font-size: ${({ theme }) => theme.type.h3.fontSize};
    line-height: ${({ theme }) => theme.type.h3.lineHeight};
    margin: ${({ theme }) => theme.type.h3.margin};
  }

  h4 {
    font-size: ${({ theme }) => theme.type.h4.fontSize};
    line-height: ${({ theme }) => theme.type.h4.lineHeight};
    margin: ${({ theme }) => theme.type.h4.margin};
  }

  h5 {
    font-size: ${({ theme }) => theme.type.h5.fontSize};
    line-height: ${({ theme }) => theme.type.h5.lineHeight};
    margin: ${({ theme }) => theme.type.h5.margin};
  }

  h6 {
    font-size: ${({ theme }) => theme.type.h6.fontSize};
    line-height: ${({ theme }) => theme.type.h6.lineHeight};
    margin: ${({ theme }) => theme.type.h6.margin};
  }

  p {
    line-height: ${({ theme }) => theme.type.h4.lineHeight};
    margin: ${({ theme }) => theme.type.h4.margin};
  }

  strong {
    font-weight: bold;
  }

  a {
    cursor: pointer;
  }
`;

App.propTypes = {
	client: PropTypes.any,
	location: PropTypes.any
};

export default compose(
	graphql(CURRENT_ADMIN_USER_QUERY, { fetchPolicy: "no-cache", name: "currentAdmin" }),
	withRouter
)(hot(module)(App));

import React from "react";
import Loadable from "react-loadable";
import styled from "styled-components";
import PropTypes from "prop-types";

class Loader extends React.Component {
	static propTypes = {
		error: PropTypes.any,
		loading: PropTypes.any
	};
	componentDidCatch(err, info) {
		console.log(err);
		console.log(info);
	}
	render() {
		if (this.props.error) {
			console.log(this.props.error);
			return <LoaderContainer>{this.props.error ? JSON.stringify(this.props.error) : null}</LoaderContainer>;
		} else {
			return null;
		}
	}
}

const LoaderContainer = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 24px;
	text-align: center;
	background: #000;
	color: #aaa;
`;
// Home route

export const Home = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "@common/website/components/routes/home"),
	loading: props => <Loader {...props} />
});

// User routes

export const Login = Loadable({
	loader: () => import(/* webpackChunkName: "login" */ "@common/website/components/routes/user/login"),
	loading: props => <Loader {...props} />
});

// Office routes

export const Office = Loadable({
	loader: () => import(/* webpackChunkName: "office" */ "@common/website/components/routes/office"),
	loading: props => <Loader {...props} />
});

export const OfficesInVicinity = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "offices_in_vicinity" */ "@common/website/components/routes/office/offices_in_vicinity"
		),
	loading: props => <Loader {...props} />
});

// Broker routes

export const Broker = Loadable({
	loader: () => import(/* webpackChunkName: "broker" */ "@common/website/components/routes/broker"),
	loading: props => <Loader {...props} />
});

// Property routes

export const Property = Loadable({
	loader: () =>
		import(/* webpackChunkName: "property" */ "@common/website/components/routes/properties/shared/property"),
	loading: props => <Loader {...props} />
});

export const PropertyRegister = Loadable({
	loader: () =>
		import(/* webpackChunkName: "property_register" */ "@common/website/components/routes/properties/shared/register"),
	loading: props => <Loader {...props} />
});

export const PropertySearch = Loadable({
	loader: () =>
		import(/* webpackChunkName: "property_search" */ "@common/website/components/routes/properties/search/index"),
	loading: props => <Loader {...props} />
});

// Custom pages

export const Kjop = Loadable({
	loader: () => import(/* webpackChunkName: "custom_kjop" */ "@common/website/components/routes/custom/kjop"),
	loading: props => <Loader {...props} />
});

export const Qr = Loadable({
	loader: () => import(/* webpackChunkName: "custom_qr" */ "@common/website/components/routes/custom/qr"),
	loading: props => <Loader {...props} />
});

export const QrBergen = Loadable({
	loader: () =>
		import(/* webpackChunkName: "custom_qr_bergen" */ "@common/website/components/routes/custom/qrverdibergen"),
	loading: props => <Loader {...props} />
});

export const ValdresKampanje = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "custom_valdres_kampanje" */ "@common/website/components/routes/custom/valdreskampanje"
		),
	loading: props => <Loader {...props} />
});

export const NybyggBergen = Loadable({
	loader: () =>
		import(/* webpackChunkName: "custom_nybygg_bergen" */ "@common/website/components/routes/custom/nybyggbergen"),
	loading: props => <Loader {...props} />
});

export const Kampanjeside = Loadable({
	loader: () => import(/* webpackChunkName: "kampanjeside" */ "@common/website/components/routes/kampanjeside"),
	loading: props => <Loader {...props} />
});

// Status code routes

export const ServerError = Loadable({
	loader: () =>
		import(/* webpackChunkName: "server_error" */ "@common/website/components/routes/status_codes/server_error"),
	loading: props => <Loader {...props} />
});

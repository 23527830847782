import { type } from "./type";
import { spacing } from "./spacing";
import { colors } from "./colors";
import { mediaQueries } from "./media_queries";

const theme = {
  type,
  spacing,
  colors,
  mediaQueries
};

export default theme;

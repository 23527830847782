import React from "react";
import styled from "styled-components";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";

import { GET_MUNICIPALITIES_QUERY } from "@common/website/gql/queries/municipalities.gql";
import checkmark from "@common/website/assets/svg/checkmark.svg";

class Checkbox extends React.Component {
	static propTypes = {
		label: PropTypes.string,
		value: PropTypes.any,
		onChange: PropTypes.any,
		bucket: PropTypes.any,
		checked: PropTypes.any,
		client: PropTypes.any,
		county: PropTypes.any,
		onSubChange: PropTypes.any,
		citiesBucket: PropTypes.any,
		bydelBucket: PropTypes.any,
		kommuneomraadeBucket: PropTypes.any,
		locations: PropTypes.any,
		idx: PropTypes.any,
		counties: PropTypes.any
	};

	state = {
		showLocations: false,
		locations: [],
		selectedLocations: []
	};

	componentDidMount() {
		if (this.props.checked && !this.state.locations.length) {
			this.getLocations();
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.checked && this.props.checked && this.props.county) {
			this.getLocations();
		}
		if (prevProps.checked && !this.props.checked) {
			this.setState({ showLocations: false, selectedLocations: [] }, () => {});
		}
	}

	getLocations = async () => {
		const {
			data: { getMunicipalities }
		} = await this.props.client.query({
			query: GET_MUNICIPALITIES_QUERY,
			variables: {
				counties: [this.props.label],
				useArea: this.props.label === "Oslo"
			}
		});
		const locationsArr = getMunicipalities?.list?.map(item => {
			return { name: item.key };
		});
		this.setState({ locations: locationsArr || [] }, () => {
			this.setState({ showLocations: true });
		});
	};

	render() {
		const {
			label,
			value,
			bucket,
			subLocationsBucket,
			municipalityAreaBucket,
			locations,
			checked,
			onChange,
			idx
		} = this.props;
		const municipalities = bucket?.length && bucket[0]?.count ? bucket[0].count : null;
		const doc_count = municipalities;
		return (
			<Container>
				<CheckboxInput
					id={label}
					type="checkbox"
					checked={idx != -1 ? true : false}
					onChange={() => onChange(parseInt(value) ? value : value, null, false)}
				/>
				<Label htmlFor={label}>
					<Checkmark dangerouslySetInnerHTML={{ __html: checkmark }} />
					<Text>
						{label} {doc_count ? <Count>({doc_count})</Count> : null}
					</Text>
				</Label>
				{this.props.county && this.state.showLocations && this.state.locations && this.state.locations.length
					? this.state.locations.map((loc, i) => {
							const checked =
								(locations &&
									locations.length &&
									locations.filter(item => item.id === loc.name || item.id === loc.search).length > 0) ||
								false;
							const municipalityAreaCount =
								municipalityAreaBucket && municipalityAreaBucket.length
									? municipalityAreaBucket.filter(b => b.key === loc.name).map(item => item.count)
									: 0;
							const municipalityCount =
								subLocationsBucket && subLocationsBucket.length
									? subLocationsBucket.filter(b => b.key === loc.name).map(item => item.count)
									: 0;
							const subCount = label === "Oslo" ? municipalityAreaCount : municipalityCount;

							return (
								<Container nested first={i === 0} key={`sub_loc_${i}`}>
									<CheckboxInput
										id={loc.name}
										type="checkbox"
										checked={checked}
										onChange={() => {
											onChange(loc.name, loc.name, true);
										}}
									/>
									<Label htmlFor={loc.name}>
										<Checkmark dangerouslySetInnerHTML={{ __html: checkmark }} />
										<Text>
											{loc.name} {subCount > 0 ? <Count>({subCount})</Count> : null}
										</Text>
									</Label>
								</Container>
							);
					  })
					: null}
			</Container>
		);
	}
}

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	flex-flow: column;
	width: 100%;
	margin-top: ${({ nested, first }) => (nested && first ? "12px" : "0")};
	margin-bottom: 10px;
	margin-left: ${({ nested }) => (nested ? "35px" : "0")};

	${({ theme }) => theme.mediaQueries.tablet`
		margin-bottom: 0;
		margin-top: 0;
		margin-left: ${({ nested }) => (nested ? "27px" : "0")};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		margin-bottom: 0;
		margin-top: 0;
		margin-left: ${({ nested }) => (nested ? "27px" : "0")};
	`};
`;

const Label = styled.label`
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	&:before {
		content: "";
		display: inline-block;
		width: ${({ theme }) => theme.type.h3.fontSize};
		height: ${({ theme }) => theme.type.h3.fontSize};
		background: ${({ theme }) => theme.colors.grey.dark};
	}

	${({ theme }) => theme.mediaQueries.tablet`
		&:before {
			width: ${({ theme }) => theme.type.h4.fontSize};
			height: ${({ theme }) => theme.type.h4.fontSize};
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		&:before {
			width: ${({ theme }) => theme.type.h4.fontSize};
			height: ${({ theme }) => theme.type.h4.fontSize};
		}
	`};
`;

const CheckboxInput = styled.input`
	position: absolute;
	left: -999px;

	&:checked + ${Label} {
		svg {
			display: flex;
		}
	}
`;

const Checkmark = styled.span`
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ theme }) => theme.type.h3.fontSize};
	height: ${({ theme }) => theme.type.h3.fontSize};

	svg {
		display: none;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};

		path {
			fill: ${({ theme }) => theme.colors.gold.primary};
		}
	}

	${({ theme }) => theme.mediaQueries.tablet`
		top: 4px;
		left: 0;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};

		svg {
			display: none;
			width: ${({ theme }) => theme.type.h5.fontSize};
			height: ${({ theme }) => theme.type.h5.fontSize};

			path {
				fill: ${({ theme }) => theme.colors.gold.primary};
			}
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		top: 4px;
		left: 0;
		width: ${({ theme }) => theme.type.h4.fontSize};
		height: ${({ theme }) => theme.type.h4.fontSize};

		svg {
			display: none;
			width: ${({ theme }) => theme.type.h5.fontSize};
			height: ${({ theme }) => theme.type.h5.fontSize};

			path {
				fill: ${({ theme }) => theme.colors.gold.primary};
			}
		}
	`};
`;

const Text = styled.span`
	margin-left: 12px;
`;

const Count = styled.span`
	font-size: 13px;
	opacity: 0.4;
`;

export default withApollo(Checkbox);
